.single-play {
    hr {
        margin: 0;
    }

    >.top {
        background-image: url("../images/play-bg.png");
        text-align: center;
        padding: 35px;

        .wave {
            margin-bottom: 15px;
        }

        .title {
            color: #fff;
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    .inner-menu {
        background-color: #2c1641;
        padding: 20px;

        ul {
            padding: 0;
            margin: 0;
            text-align: center;

            li {
                display: inline;

                &:not(:last-child)::after {
                    content: "|";
                    margin: 10px;
                    color: #fff;
                    font-size: 16px;
                }

                a {
                    color: #fff;
                    font-size: 16px;
                    text-decoration: none;
                    transition: all ease 0.4s;

                    &:hover {
                        color: #3faa97;
                    }
                }
            }
        }
    }

    .breadcrumbs {
        background-color: #f4ede5;
        padding: 35px 0 40px 0;

        .bcn {
            color: #585858;
            font-size: 16px;

            a {
                color: #585858;
                font-size: 16px;

                &.current-item {
                    color: #000000;
                    text-decoration: underline;
                }

                &:hover {
                    text-decoration: none;
                }

                &:not(:last-child) {
                    &::after {
                        content: ">";
                        margin: 0 5px;
                    }
                }
            }
        }
    }

    >.text {
        background-color: #f4ede5;
        padding-bottom: 10px;

        p {
            width: 50%;
            line-height: 1.6;

            @include respond(768) {
                width: 100%;
            }
        }
    }

    .subjects {
        background-color: #f4ede5;

        ul {
            padding: 0;
            margin: 0;

            li {
                display: inline-block;

                &:not(:last-child)::after {
                    content: "|";
                    margin: 0 20px;
                    font-size: 26px;
                    color: #c6c6c6;
                    font-weight: 300;
                }

                a {
                    color: #c6c6c6;
                    font-size: 26px;
                    font-weight: 300;
                    transition: all ease 0.4s;
                    text-decoration: none;

                    &:hover {
                        color: #3faa97;
                    }

                    &.active {
                        color: #3faa97;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    >.content {
        background-color: #f4ede5;
        padding: 15px 0 0 0;

        hr {
            &:first-of-type {
                margin-bottom: 20px;
            }
        }

        .lessons {
            position: relative;
            display: inline-block;
            margin-bottom: 20px;

            .current-lesson {
                font-size: 16px;
                padding: 5px 30px 5px 0px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transition: all ease 0.4s;
                position: relative;
                cursor: pointer;

                &:hover {
                    background-color: #2c1641;
                    padding: 5px 30px 5px 30px;
                    color: #fff;

                    i {
                        background-image: url("../images/arrow-down-white.png");
                    }
                }

                &.opened {
                    background-color: #2c1641;
                    padding: 5px 30px 5px 30px;
                    color: #fff;

                    i {
                        background-image: url("../images/arrow-down-white.png");
                    }
                }

                i {
                    width: 12px;
                    height: 6px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url("../images/arrow-down-black.png");
                }
            }

            .lessons-list {
                display: none;
                background-color: #674d79;
                position: absolute;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                z-index: 100;
                top: 27px;

                li {
                    padding: 5px;
                    text-align: center;
                    transition: all ease 0.4s;

                    &:last-child {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }

                    &:hover {
                        background-color: #2c1641;
                    }

                    a {
                        color: #f4ede5;
                        text-decoration: none;
                    }
                }
            }
        }

        .video-wrapper {
            height: 480px;
            width: 100%;
            display: flex;

            @include respond(768) {
                flex-wrap: wrap;
                height: auto;

                >div {
                    width: 100% !important;
                }
            }

            .player,
            .navigation {
                height: 100%;
            }

            .player {
                width: 70%;
                border-radius: 10px;

                @include respond(768) {
                    margin: 50px 0;
                }

                iframe {
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .navigation {
                width: 30%;
                position: relative;
                padding-left: 10px;

                @include respond(768) {
                    height: auto;
                }

                .items-wrapper {
                    height: 408px;
                    overflow-y: auto;
                    padding-right: 5px;

                    @include respond(768) {
                        height: auto;
                    }

                    &.full {
                        height: 100%;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: rgba($color: #000000, $alpha: 0.05);
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #3faa97;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #2d7a6c;
                    }

                    .item {
                        background-color: #ebdccc;
                        padding: 15px;
                        border-radius: 10px;
                        margin-bottom: 8px;
                        transition: all ease 0.4s;
                        cursor: pointer;
                        display: flex;

                        &:hover {
                            background-color: #674d79;

                            .name,
                            .time {
                                color: #fff;
                            }
                        }

                        &.current {
                            background-color: #2c1641;

                            .name,
                            .time {
                                transition: all ease 0.4s;
                                color: #fff;
                            }
                        }

                        .paly-button-wrapper {
                            width: 20%;

                            .paly-button {
                                background-color: rgba($color: #fff, $alpha: 0.2);
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                @include respond(992) {
                                    height: 20px;
                                    width: 20px;

                                    img {
                                        max-width: 8px;
                                    }
                                }
                            }
                        }

                        .name-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 65%;

                            h2 {
                                font-size: 16px;
                                margin: 0;

                                @include respond(992) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .time-wrapper {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: 15%;

                            h2 {
                                font-size: 16px;
                                margin: 0;

                                @include respond(992) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .next {
                    background-color: #3faa97;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    padding: 20px 0;
                    cursor: pointer;
                    transition: all ease 0.4s;
                    margin-top: 8px;
                    color: #fff;
                    font-size: 16px;
                    margin-bottom: 0;
                    font-weight: 600;
                    text-decoration: none;

                    &:hover {
                        background-color: #2c1641;
                    }
                }
            }
        }

        .downloads {
            padding: 30px 0 60px 0;

            .download {
                display: inline-block;
                margin-right: 20px;

                a {
                    color: #3faa97;
                    text-decoration: none;
                    transition: all ease 0.4s;

                    img {
                        margin-right: 5px;
                        transition: all ease 0.4s;
                    }

                    &:hover {
                        img {
                            transform: scale(1.15);
                        }
                    }
                }
            }
        }
    }

    .comments {
        background-color: #f4ede5;
        padding: 15px 0 0 0;

        .title {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 0;
            margin-bottom: 25px;
        }

        .comments-wrapper {
            .comment {
                display: flex;
                border-bottom: 1px solid #dbd5ce;
                margin-bottom: 30px;
                padding-bottom: 30px;

                .image {
                    width: 10%;

                    img {
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                        border-radius: 100%;
                    }
                }

                .content {
                    padding: 0;
                    width: 80%;
                    padding: 0 20px;

                    .name {
                        line-height: 1;
                        margin-bottom: 10px;
                        font-size: 18px;
                        font-weight: 700;
                    }

                    .date {
                        line-height: 1;
                        margin-bottom: 10px;
                        font-size: 14px;
                        color: #95918c;
                        font-weight: 600;
                    }

                    .text {
                        font-size: 16px;
                    }
                }

                .btns {
                    width: 10%;

                    a {
                        display: block;
                        background-color: #928e89;
                        color: #fff;
                        border-radius: 20px;
                        padding: 7px 5px;
                        width: 75px;
                        text-align: center;
                        text-decoration: none;
                        line-height: 1;
                        transition: all ease 0.4s;
                        margin-bottom: 5px;
                        font-size: 14px;
                        font-weight: 300;

                        &:hover {
                            background-color: #5f5f5f;
                        }
                    }
                }
            }

            .children {
                padding: 0;

                .comment {
                    .image {
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "↳";
                            font-size: 22px;
                            left: -20px;
                            top: 15px;
                            color: #ccc6bf;
                        }
                    }
                }
            }
        }

        .replay-wrapper {
            padding-bottom: 20px;

            form {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                textarea,
                input {
                    background-color: #e5ded7;
                    border: unset;
                    resize: none;
                    width: 100%;
                    margin-bottom: 20px;
                    border-radius: 10px;
                    padding: 15px;
                    transition: all ease 0.4s;

                    &:focus {
                        outline: none;
                        background-color: #674d79;
                        color: #fff;

                        &::placeholder {
                            color: #fff;
                        }
                    }

                    &::placeholder {
                        color: #a29d98;
                    }
                }

                textarea {
                    height: 180px;
                }

                input {
                    width: 31%;

                    @include respond(768) {
                        width: 100%;
                    }

                    &:nth-of-type(2) {
                        margin-left: 20px;
                        margin-right: 20px;

                        @include respond(768) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    &[type="submit"] {
                        background-color: #3faa97;
                        color: #fff;
                        width: unset;
                        font-weight: 600;
                        border-radius: 30px;
                        padding: 15px 50px;
                        margin: 0;
                    }
                }
            }
        }
    }

    .sugested {
        background-color: #f4ede5;

        .container {
            border-top: 1px solid #dbd5ce;
            padding-bottom: 60px;

            .title {
                margin-top: 60px;
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 400px;
            }

            .sugested-video-list {
                display: flex;
                flex-wrap: wrap-reverse;
                gap: 10px;

                @include respond(992) {
                    justify-content: space-between;
                }

                .video {
                    overflow: hidden;
                    position: relative;
                    height: 144px;
                    width: 20%;

                    @include respond(992) {
                        height: auto;
                        width: 49%;
                    }

                    &:last-child {
                        padding-right: 0;

                        .info {
                            right: 0 !important;
                        }
                    }

                    &:first-child {
                        padding-left: 0;

                        .info {
                            width: 100%;
                            left: 0 !important;
                        }
                    }

                    &:hover {
                        .info {
                            top: 0;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .info {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: -100%;
                        background-color: #3faa97;
                        transition: all ease 0.4s;
                        padding: 10px 10px;
                        font-size: 14px;
                        color: #fff;

                        a {
                            display: inline-block;
                            margin-bottom: 10px;
                        }

                        img {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}