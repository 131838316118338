.woocommerce-MyAccount-navigation {
  background-color: #282e60;
  height: auto;
  color: #ebdccc;
  padding: 20px;
  a {
    color: #ebdccc;
  }
}
.woocommerce-MyAccount-content {
  background-color: #ebdccc;
  padding: 20px;
  form {
    p {
      display: block;
      label {
        display: block;
      }
      input,
      select,
      textarea,
      .select2-selection {
        width: 100%;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid #ebdccc;
        color: #282e60;
        border-radius: 35px;
        padding: 7px 20px;
        transition: all ease 0.4s;
        background-color: #f4ede5;
        &:focus {
          border: 1px solid #3faa97;
        }
        &::placeholder {
          color: #282e60;
        }
      }
      .select2-selection {
        height: 40px;
        color: #282e60;
      }
    }
  }
}

.u-columns.woocommerce-Addresses.col2-set.addresses {
  display: flex;
  .woocommerce-Address {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
