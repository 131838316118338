@import "../../librarys/bootstrap/bootstrap.min.css";
@import "../../librarys/slick/slick.css";
@import "../../librarys/slick/slick-theme.css";
@import "./fonts/fonts";
@import "./fonts/font_awesome";
@import "theme/mixins";
@import "theme/404";
@import "theme/index";
@import "theme/search";
@import "theme/general";
@import "theme/header";
@import "theme/homepage";
@import "theme/about";
@import "theme/product";
@import "theme/play";
@import "theme/community";
@import "theme/single";
@import "theme/support";
@import "theme/career";
@import "theme/contact";
@import "theme/product-cat";
@import "theme/footer";
@import "theme/woocommerce-cart";
@import "theme/woocommerce-form";
@import "theme/woocommerce-checkout";
@import "theme/woocommerce-myaccount";