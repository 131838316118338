.woocommerce-cart {
  table.shop_table {
    border: none;
    thead {
      th {
        text-transform: uppercase;
      }
    }
    td {
      border-top: 1px solid #ebdccc;
      &.product-remove {
        a {
          color: #dc3545 !important;
          font-weight: 300;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;
          transition: all ease 0.4s;
          &:hover {
            background-color: #dc3545;
            color: #fff !important;
          }
        }
      }
      &.product-name {
        a {
          color: #282e60;
        }
      }
      &.product-quantity {
        input {
          border: 1px solid #282e60;
          background: transparent;
          border-radius: 20px;
          padding: 5px;
          &:focus {
            outline: none;
          }
        }
      }
      &.actions {
        .coupon {
          input {
            border: 1px solid #282e60 !important;
            background: transparent;
            border-radius: 20px;
            padding: 6px 10px !important;
            width: 200px;
            transition: all ease 0.4s;
            &:focus {
              padding: 6px 20px !important;
              outline: none;
            }
          }
        }
      }
    }
  }
  .cart_totals {
    h2 {
      text-transform: uppercase;
      color: #282e60;
      font-weight: 600;
      font-size: 28px;
    }
    td,
    th {
      border-top: 1px solid #ebdccc !important;
    }
  }
}
