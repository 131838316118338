.checkout.woocommerce-checkout {
    .checkout-wrapper {

        .billing-details,
        .additional-information {
            width: 100%;
            background-color: #282e60;
            color: #ebdccc;
            padding: 50px 100px;
            font-size: 18px;

            @include respond(1200) {
                padding: 15px;
            }

            p {
                display: block;

                label {
                    display: block;
                }

                input,
                select,
                textarea,
                .select2-selection {
                    width: 100%;
                    font-size: 16px;
                    background-color: transparent;
                    border: 1px solid #282e60;
                    color: #282e60;
                    border-radius: 35px;
                    padding: 7px 20px;
                    transition: all ease 0.4s;
                    background-color: #f4ede5;

                    &:focus {
                        border: 1px solid #3faa97;
                    }

                    &::placeholder {
                        color: #282e60;
                    }
                }

                .select2-selection {
                    height: 40px;
                    color: #282e60;
                }
            }
        }

        .woocommerce-billing-fields__field-wrapper {
            display: flex;
            flex-wrap: wrap;

            p {
                width: 50%;
                height: 91px;

                @include respond(992) {
                    height: auto;
                }

                @include respond(768) {
                    width: 100%;
                }

                .screen-reader-text {
                    clip: unset;
                    height: unset;
                    overflow: unset;
                    position: unset !important;
                    width: unset;
                    word-wrap: unset !important;
                }
            }
        }

        .select2-selection__rendered {
            color: #282e60;
        }
    }

    .select2-dropdown {
        background-color: #ebdccc !important;
    }

    #order_review_heading {
        color: #282e60;
        text-align: center;
        margin: 30px;
        text-transform: uppercase;
    }
}

.select2-dropdown {
    background-color: #3faa97;
    top: 10px;
    border-radius: 30px !important;
    padding: 30px !important;

    input {
        width: 100%;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid #282e60;
        color: #ebdccc;
        border-radius: 35px;
        padding: 10px 20px !important;
        background-color: #282e60;

        &:focus {
            outline: none;
        }
    }

    .select2-results__option--highlighted {
        background-color: #3c416d !important;
        color: #f4ede5;

        &:focus {
            outline: none;
        }
    }

    .select2-results__option[data-selected="true"] {
        background-color: #282e60 !important;
        color: #f4ede5;

        &:focus {
            outline: none;
        }
    }
}

.woocommerce-form-coupon {
    background-color: #282e60;
    color: #ebdccc;

    input {
        font-size: 16px;
        background-color: transparent;
        border: 1px solid #282e60;
        color: #282e60;
        border-radius: 35px;
        padding: 7px 20px;
        transition: all ease 0.4s;
        background-color: #f4ede5;
    }
}