.page-template-about {
    .top {
        height: calc(100vh - 82px);
        position: relative;

        .image,
        .video {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;
        }

        .image {
            z-index: 2;

            .play-icon {
                width: unset;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }

            img {
                width: 100%;

                &.large-image {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .video {
            z-index: 1;

            iframe {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 125%;
            }
        }
    }

    .content {
        padding: 60px 0px;
        text-align: center;
        background-color: #f4ede5;

        @include respond(468) {
            padding: 60px 0px 10px;
        }

        &.purple {
            background-color: #2c1641;
            color: #fff;
        }

        .wave {
            margin-bottom: 20px;
        }

        .title,
        .sub-title {
            font-size: 30px;
            font-weight: 300;
            margin: 0;
            line-height: 1.2;
            text-transform: uppercase;
        }

        .sub-title {
            color: #3faa97;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            margin-bottom: 35px;
            padding: 0 100px;

            @include respond(768) {
                padding: 0;
            }
        }

        iframe {
            width: 100%;
            height: 450px;

            @include respond(768) {
                min-height: 190px;
            }

            @include respond(468) {
                height: auto;
                margin-bottom: 40px;
            }
        }
    }

    .staff {
        padding: 60px 0px;
        text-align: center;
        background-color: #ebdccc;

        .wave {
            margin-bottom: 20px;
        }

        .title,
        .sub-title {
            font-size: 30px;
            font-weight: 300;
            margin: 0;
            line-height: 1.2;
            text-transform: uppercase;
        }

        .sub-title {
            color: #3faa97;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            margin-bottom: 35px;
        }

        .staff-list {
            margin-left: -5px;
            margin-right: -5px;

            .col-3 {
                padding-right: 5px;
                padding-left: 5px;

                @include respond(1200) {
                    flex: 0 0 33%;
                    max-width: 33%;
                }

                @include respond(768) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            .single-staff {
                background-color: #efe4d7;
                margin-bottom: 10px;
                padding: 25px 25px 15px 25px;
                position: relative;
                overflow: hidden;

                &:hover {
                    .image {
                        img {
                            transform: scale(1.1);

                            @include respond(768) {
                                transform: none;
                            }
                        }
                    }
                }

                .image {
                    height: 250px;
                    border-radius: 50%;
                    overflow: hidden;

                    @include respond(768) {
                        height: 389px;
                        border-radius: 0;
                    }

                    @include respond(468) {
                        height: 277px;
                    }

                    img {
                        transition: all ease 0.4s;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                .text {
                    max-width: 86%;
                }

                .name {
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                    margin-top: 20px;
                    text-align: left;
                }

                .role {
                    font-size: 18px;
                    font-weight: 200;
                    color: #3faa97;
                    text-align: left;
                    margin-bottom: 0;

                    @include respond(768) {
                        margin-left: 0;
                    }
                }

                .more-btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: #f3eae1;
                    font-size: 30px;
                    color: #3faa97;
                    font-weight: 200;
                    line-height: 1;
                    height: 55px;
                    width: 55px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .more {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #3faa97;
                    top: 100%;
                    left: 100%;
                    padding: 25px 25px 0 25px;
                    color: #fff;
                    text-align: left;
                    transition: all ease 0.4s;

                    &.opened {
                        top: 0;
                        left: 0;
                    }

                    .name {
                        font-size: 18px;
                        font-weight: 700;
                        margin: 0;
                    }

                    .role {
                        color: #fff;
                        font-size: 14px;
                        font-weight: 200;
                        margin-bottom: 15px;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }

                    .close-more-btn {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: transparent;
                        font-size: 30px;
                        color: #fff;
                        font-weight: 200;
                        line-height: 1;
                        height: 55px;
                        width: 55px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}