.woocommerce form.login,
.woocommerce-ResetPassword.lost_reset_password {
  background-color: #282e60;
  color: #ebdccc;
  width: 50%;
  margin: auto;
  padding: 50px 100px 100px 100px;
  font-size: 18px;
  position: relative;
  .msg {
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    ul {
      margin: 0;
    }
  }
  h2 {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  p {
    display: block;
    label {
      display: block;
    }
    input {
      width: 100%;
      font-size: 16px;
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 35px;
      padding: 7px 20px;
      transition: all ease 0.4s;
      &:focus {
        border: 1px solid #3faa97;
      }
    }
    button {
      display: block;
      width: 100%;
    }
    .woocommerce-form-login__rememberme {
      display: flex;
      align-items: center;
      input,
      span {
        width: auto;
        display: inline-block;
      }
      input {
        margin-right: 10px;
      }
    }
    &.lost_password {
      a {
        display: inline-block;
        color: #ebdccc;
        margin-top: 30px;
      }
    }
    &.form-row-first {
      width: 100%;
    }
  }
}
