.page-template-support {
    .top {
        background-image: url("../images/support-bg.png");
        height: 368px;

        .container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .title {
                font-size: 30px;
                font-weight: 700;
                margin: 0;
                line-height: 1.2;
                color: #fff;
                text-transform: uppercase;
            }

            ul {
                margin: unset;
                padding: unset;
                list-style: none;
                margin-top: 60px;

                @include respond(768) {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                }

                li {
                    display: inline-block;
                    margin: 4px;

                    @include respond(768) {
                        display: flex;
                        margin: 0;
                    }

                    a {
                        font-size: 16px;
                        font-weight: 600;
                        color: #ffffff;
                        padding: 10px 30px;
                        border: 1px solid #fff;
                        border-radius: 35px;
                        text-decoration: none;
                        transition: all ease 0.4s;

                        &:hover {
                            background-color: #3faa97;
                            border: 1px solid #3faa97;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .help {
        background-color: #f4ede5;
        text-align: center;
        padding: 60px 0;

        .wave {
            margin-bottom: 20px;
        }

        .title,
        .sub-title {
            font-size: 30px;
            font-weight: 300;
            margin: 0;
            line-height: 1.2;
        }

        .sub-title {
            color: #3faa97;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            margin-bottom: 35px;
        }

        .video-list {
            .video {
                padding: 6px;

                @include respond(768) {
                    width: 100% !important;
                }

                iframe {
                    height: 220px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                    margin-bottom: 20px;
                }

                h2 {
                    font-size: 18px;
                    font-weight: 400;
                }
            }

            .slick-arrow {
                height: 28px;
                width: 15px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 28px;
                    width: 15px;
                    background-image: url("../images/slick-arrow-dark.png");
                    opacity: 0.7;
                }

                &:hover {
                    &::before {
                        opacity: 1;
                    }
                }

                &.slick-prev {
                    &::before {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        hr {
            background-color: #dfc6ac;
            margin: 45px 0;
        }

        .downloads-wrapper {
            margin: auto;
            display: flex;
            justify-content: space-between;
            width: 700px;

            @include respond(768) {
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
                gap: 40px;
            }

            .download {
                text-decoration: none;

                @include respond(768) {
                    min-width: 33%;
                }

                &:hover {
                    .icon {
                        img {
                            transform: scale(1);
                        }
                    }
                }

                .icon {
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        transform: scale(0.9);
                        transition: all ease 0.4s;
                    }
                }

                h2 {
                    font-size: 16px;
                    color: #000;
                }
            }
        }
    }

    .app {
        // background-image: url("../images/app-bg.png");
        // padding-top: 100px;
        background-color: #efe4d7;
        padding: 110px 0px;

        >.container {
            >.row {
                justify-content: center;
            }
        }

        @include respond(992) {
            >.container {
                >.row {
                    justify-content: space-around;

                    @include respond(768) {
                        justify-content: center;
                        text-align: center;
                        flex-wrap: wrap-reverse;
                    }

                    >div {
                        flex: 100%;
                        max-width: 100%;
                    }

                    .image {
                        display: contents;
                    }
                }
            }
        }

        @include respond(768) {
            padding-top: 70px;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .content {
            color: #fff;
            max-width: 550px;
            text-align: center;

            @include respond(768) {
                margin-bottom: 50px;
            }

            .wave {
                margin-bottom: 20px;
            }

            .title {
                font-size: 30px;
                font-weight: 300;
                margin: 0;
                line-height: 1.2;
                margin-bottom: 20px;
                text-transform: uppercase;
                color: #3faa97;
            }

            p {
                font-size: 18px;
                font-weight: 300;
                line-height: 1.6;
                font-weight: 300;
                color: #000;

                &:last-of-type {
                    margin-bottom: 30px;
                }
            }

            // a {
            //     font-size: 14px;
            //     font-weight: 600;
            //     border-radius: 30px;
            //     padding: 10px 35px;
            //     color: #fff;
            //     text-decoration: none;
            //     transition: all ease 0.4s;
            //     text-transform: uppercase;
            //     display: flex;
            //     flex: 100%;
            //     justify-content: center;

            //     &.android {
            //         border: 1px solid #f4ede5;
            //         margin-bottom: 10px;

            //         &:hover {
            //             color: #377272;
            //             background-color: #f4ede5;
            //         }
            //     }

            //     &.apple {
            //         border: 1px solid #dfc7ad;

            //         &:hover {
            //             color: #fff;
            //             background-color: #dfc7ad;
            //         }
            //     }
            // }
        }
    }

    .fqa {
        background-color: #f4ede5;
        padding: 45px;
        text-align: center;

        @include respond(768) {
            padding: 45px 15px;
        }

        .wave {
            margin-bottom: 20px;
        }

        .title,
        .sub-title {
            font-size: 30px;
            font-weight: 300;
            margin: 0;
            line-height: 1.2;
        }

        .sub-title {
            color: #3faa97;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            width: 600px;
            text-align: center;
            margin: auto;
            margin-bottom: 35px;

            @include respond(768) {
                width: 100%;
            }
        }

        .fql-list {
            margin: auto;
            width: 800px;

            @include respond(992) {
                width: 100%;
            }

            .single-fqa {
                margin-bottom: 10px;

                .letter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 30px;
                }

                .text {
                    display: flex;
                    align-items: center;

                    p {
                        margin: 0;
                        text-align: left;
                        font-size: 18px;
                    }
                }

                .toggle-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include respond(768) {
                        padding: 0;
                    }

                    img {
                        transition: all ease 0.4s;
                        cursor: pointer;
                        padding: 10px;

                        @include respond(992) {
                            padding: 0;
                        }
                    }
                }

                .question {
                    padding: 25px 30px;
                    background-color: #efe4d7;

                    &.opened {
                        .toggle-icon {
                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .answer {
                    display: none;
                    padding: 25px 30px;
                    background-color: #3faa97;
                    color: #fff;
                }
            }
        }
    }
}