body.p404 {
	background-image: url("../404_images/background.png");
	background-size: auto;
	* {
		font-family: Poppins, sans-serif !important;
	}
	.container {
		max-width: 1920px;
		color: white;
		.row {
			h1 {
				font-size: 110px;
				font-weight: 700;
				margin-bottom: 35px;
			}
			p {
				color: #ff0000;
				font-size: 27px;
				padding-top: 45px;
				padding-left: 24px;
			}
			h2 {
				font-size: 100px;
				font-weight: 700;
				margin-bottom: 45px;
			}
			h3 {
				font-size: 49.67px;
				font-weight: 700;
				margin-bottom: 68px;
			}
			.btn {
				font-size: 36.06px;
				font-weight: 400;
				border-radius: 7.25rem;
				width: 413px;
				margin-bottom: 16px;
			}
			.btn-outline-danger {
				color: #e90205;
				border-color: #e90205;
			}
			.btn-outline-danger:hover {
				color: #fff;
				border-color: #e90205;
			}
			.top-line {
				color: #e90205;
			}
		}
		.mt-5 {
			margin-top: 8rem !important;
		}
	}
	.theguy {
		padding-left: 64px;
	}
}

// RESPONSIVE

@media (max-width: 992px) {
	body {
		.container {
			.row {
				.top-line {
					display: block;
				}
				h1 {
					font-size: 80px;
				}
				p {
					padding-left: 0;
					padding-top: 0;
				}
				h2 {
					font-size: 45px;
				}
				h3 {
					font-size: 24px;
				}
				.btn {
					font-size: 25px;
					width: 300px;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	body {
		.container {
			.row {
				.top-line {
					display: block;
				}
				h1 {
					font-size: 70px;
					margin-bottom: 10px;
				}
				p {
					font-size: 20px;
				}
				h3 {
					margin-left: -140px;
					margin-bottom: 20px;
				}
				.btn {
					margin-left: -130px;
				}
			}
			.mt-5 {
				margin-top: 2rem !important;
			}
		}
		.theguy {
			padding-left: 114px;
			padding-top: 20px;
		}
	}
}
