.page-template-contact {
    .container-fluid {
        height: 100vh;

        @include respond(768) {
            height: auto;
        }

        .row {
            height: 100%;

            @include respond(768) {
                >div {
                    flex: 100%;
                    max-width: 100%;
                }
            }

            .lets-talk {
                height: 100%;
                background-color: #4b2f65;
                padding: 0;

                .content {
                    width: 280px;
                    margin-left: auto;
                    padding-right: 20px;
                    padding-top: 130px;

                    @include respond(1200) {
                        width: 100%;
                        padding-left: 20px;
                    }

                    @include respond(992) {
                        word-break: break-word;
                    }

                    @include respond(768) {
                        padding-top: 70px;
                    }

                    .title {
                        font-size: 32px;
                        color: #fff;
                        font-weight: 700;
                        padding-bottom: 40px;
                        border-bottom: 1px solid #dfc8ae;
                        margin-bottom: 55px;
                        text-transform: uppercase;
                    }

                    label {
                        display: block;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    span {
                        display: block;
                        margin-bottom: 35px;
                        color: #fff;
                        font-weight: 200;
                    }

                    a {
                        display: inline-block;
                        color: #fff;
                        margin-bottom: 35px;
                        font-weight: 200;

                        &.phone {
                            color: #3faa97;
                            font-weight: 700;
                        }
                    }
                }
            }

            .write-to-us {
                background-image: url("../images/contact-bg.png");
                background-size: cover;
                padding: 0;

                .content {
                    padding-top: 130px;
                    width: 660px;
                    padding-left: 65px;

                    @include respond(1200) {
                        width: 100%;
                        padding-left: 25px;
                        padding-right: 25px;
                    }

                    @include respond(768) {
                        padding-top: 70px;
                        padding-bottom: 70px;
                    }

                    .title {
                        font-size: 32px;
                        color: #fff;
                        font-weight: 700;
                        padding-bottom: 40px;
                        border-bottom: 1px solid #dfc8ae;
                        margin-bottom: 55px;
                        text-transform: uppercase;
                    }

                    .subtitle {
                        color: #3faa97;
                        font-weight: 600;
                        font-size: 24px;
                        margin-bottom: 0;
                    }

                    p {
                        color: #fff;
                        font-size: 18px;
                        font-weight: 200;
                    }

                    .wpcf7 {
                        .wpcf7-not-valid-tip {
                            display: none;
                        }

                        .wpcf7-response-output {
                            border: none;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 300;
                            padding: 0;
                            margin: 0;
                            margin-top: 20px;
                        }

                        .ajax-loader {
                            display: none;
                        }

                        .input-wrapper {
                            position: relative;

                            label {
                                position: absolute;
                                top: 50%;
                                left: 20px;
                                transform: translateY(-50%);
                                color: #fff;
                                font-size: 14px;
                                font-weight: 600;
                                transition: all ease 0.4s;
                                padding: 0 5px;
                                z-index: 100;

                                &.textarea {
                                    top: 25px;
                                }

                                &.focused {
                                    top: 0;
                                    font-size: 12px;

                                    &.name {
                                        background-color: #2a3456;
                                    }

                                    &.email {
                                        background-color: #262d50;
                                    }

                                    &.phone {
                                        background-color: #232547;
                                    }

                                    &.textarea {
                                        background-color: #221d41;
                                    }
                                }

                                span {
                                    color: #3faa97;
                                }
                            }

                            input,
                            textarea {
                                display: block;
                                width: 100%;
                                height: 50px;
                                background: transparent;
                                border: 1px solid rgba($color: #ffffff, $alpha: 0.2);
                                border-radius: 5px;
                                margin-bottom: 15px;
                                transition: all ease 0.4s;
                                padding-left: 25px;
                                color: #fff;
                                font-size: 14px;
                                font-weight: 300;

                                &:focus {
                                    outline: none;
                                    border: 1px solid rgba($color: #ffffff, $alpha: 1);
                                }

                                &.wpcf7-not-valid {
                                    border: 1px solid rgba($color: #dc3545, $alpha: 0.7);
                                }
                            }

                            textarea {
                                height: 180px;
                                padding-top: 15px;
                                resize: none;
                            }
                        }

                        input[type="submit"] {
                            background-color: #3faa97;
                            border: none;
                            font-size: 16px;
                            color: #fff;
                            padding: 10px 55px;
                            border-radius: 5px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}