.single-product {
  .product {
    padding: 90px 0;
    background-image: url('../images/product-bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    @include respond(768) {
      > .container {
        > .row {
          > div {
            flex: 100%;
            max-width: 100%;
            text-align: center;
          }
        }
      }
    }

    .summary {
      @include respond(768) {
        margin-bottom: 20px;
      }

      img {
        margin-bottom: 15px;
      }

      h1 {
        font-size: 48px;
        font-weight: 300;
        color: #fff;
        line-height: 1.2;
        margin-bottom: 40px;

        strong {
          color: #3faa97;
          font-weight: 300;
        }
      }

      p {
        color: #fff;
        font-weight: 300;
        margin-bottom: 35px;
      }

      .technical-fields {
        margin-bottom: 20px;

        .field {
          color: #fff;
          font-weight: 300;
          display: flex;
          align-content: center;
          font-size: 14px;

          img {
            margin-right: 15px;
          }
        }

        + .woocommerce-Price-amount.amount {
          display: block;
          margin-bottom: 30px;
          text-align: center;
          width: 100%;
          padding: 0 30px;
          max-width: 200px;
          font-size: 25px;
          color: #fff;
          font-weight: 400;
        }
      }

      a {
        background-color: transparent;
        font-weight: 700;
        border-radius: 30px !important;
        padding: 15px 50px !important;
        transition: all ease 0.4s;
        text-decoration: none;
        line-height: unset;

        &.add_to_cart_button {
          border: 1px solid #3faa97;
          color: #3faa97;
          transition: all ease 0.4s;

          &.added {
            background-color: #3faa97;
            color: #fff;
          }

          &:hover {
            background-color: #3faa97;
            color: #fff;
          }

          &::after {
            position: absolute;
            right: 20px;
            margin: 0;
            top: unset;
            vertical-align: unset;
          }
        }

        &.added_to_cart {
          border: 1px solid #dfc7ad;
          color: #dfc7ad;

          &:hover {
            background-color: #dfc7ad;
            color: #282e60;
          }
        }
      }
    }

    .gallery {
      padding: 0;

      *:focus {
        outline: none !important;
      }

      .slider-current {
        margin-bottom: 100px;
      }

      .slider-nav {
        padding: 0 20px;

        .slick-arrow {
          height: 25px;
          width: 16px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 25px;
            width: 16px;
            background-image: url('../images/slick-arrow-beige.png');
            opacity: 1;
            transition: all ease 0.4s;
          }

          &:hover {
            &::before {
              background-image: url('../images/slick-arrow-green.png');
            }
          }

          &.slick-next {
            right: 0;
          }

          &.slick-prev {
            left: 0;

            &::before {
              transform: rotate(180deg);
            }
          }
        }

        .slide {
          padding: 0 20px;
          opacity: 0.5;
          transition: all ease 0.4s;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }

          &.slick-current {
            opacity: 1;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .downloads {
    text-align: center;
    padding: 60px 0;

    .title {
      font-size: 30px;
      font-weight: 300;
      margin: 0;
      line-height: 1.2;
      color: #3faa97;
      margin-bottom: 25px;
    }

    .downloads-wrapper {
      margin: auto;
      display: flex;
      justify-content: space-between;
      width: 700px;

      @include respond(768) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
      }

      .download {
        text-decoration: none;

        @include respond(768) {
          min-width: 33%;
        }

        &:hover {
          .icon {
            img {
              transform: scale(1);
            }
          }
        }

        .icon {
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          img {
            transform: scale(0.9);
            transition: all ease 0.4s;
          }
        }

        h2 {
          font-size: 16px;
          color: #000;
        }
      }
    }
  }

  .features {
    background-color: #f4ede5;
    padding: 100px 0;

    @include respond(768) {
      padding: 70px 0;
    }

    .title {
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 50px;
      line-height: 1.2;
      color: #3faa97;
      margin-bottom: 25px;
      text-align: center;
    }

    .tabs {
      border-bottom: 1px solid #dfc8ae;
      display: flex;

      .tab {
        border-left: 1px solid #dfc8ae;
        text-align: center;
        font-size: 16px;
        width: 230px;
        padding: 20px 0;
        cursor: pointer;

        &:last-child {
          border-right: 1px solid #dfc8ae;
        }

        &.current {
          font-weight: 600;
          border-bottom: 5px solid #3faa97;
        }
      }
    }

    .content {
      display: flex;
      padding: 60px 0;

      @include respond(768) {
        flex-wrap: wrap;

        > div {
          width: 100% !important;
        }
      }

      .video {
        width: 40%;

        @include respond(768) {
          margin-bottom: 50px;
        }

        iframe {
          width: 100%;
          height: 100%;
          border-radius: 20px;

          @include respond(768) {
            min-height: 190px;
          }
        }
      }

      .controller {
        width: 60%;
        text-align: right;
        position: relative;

        > img {
          width: 90%;

          @include respond(768) {
            width: 100%;
          }
        }

        // &[data-tab='attributes'] {
        //   display: none;
        // }

        .dot {
          position: absolute;
          background-color: #e9dac9;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
          transition: all ease 0.4s;

          @include respond(992) {
            width: 20px;
            height: 20px;

            img {
              max-width: 8px;
            }
          }

          &:hover {
            background-color: #3faa97;
          }

          &.playing {
            background-color: #3faa97;
          }

          .line {
            position: absolute;
            height: 1px;
            width: 0;
            border-bottom: 3px dotted #dfc8ae;
            right: 50px;
            transition: all ease 0.7s;

            @include respond(768) {
              display: none !important;
            }

            &::after {
              display: none;
              content: '';
              position: absolute;
              height: 25px;
              width: 16px;
              background-image: url('../images/slick-arrow-beige.png');
              transform: rotate(180deg);
              left: -15px;
              top: -11px;
            }
          }

          &.open {
            .line {
              &::after {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .accessories {
    background-color: #efe4d8;
    padding: 130px 0;
    text-align: center;

    @include respond(768) {
      padding: 70px 0;
    }

    .wave {
      margin-bottom: 20px;
    }

    .title,
    .sub-title {
      font-size: 30px;
      font-weight: 300;
      margin: 0;
      line-height: 1.2;
    }

    .sub-title {
      color: #3faa97;
      margin-bottom: 25px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;
      margin-bottom: 35px;
    }

    .accessories-list {
      background-image: url('../images/beige-wave-big-accessories.png');
      background-position: top;
      background-repeat: no-repeat;
      padding-top: 70px;

      @include respond(768) {
        padding-top: 40px;
        background-attachment: fixed;
        background-position: center;
      }

      .accessory {
        &:hover {
          .image {
            img {
              transform: scale(1.1);
            }
          }
        }

        .image {
          height: 240px;
          width: 160px;
          padding: 20px 0;
          margin: auto;
          overflow: hidden;

          @include respond(768) {
            height: auto;
            width: 100%;
          }

          img {
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all ease 0.4s;
          }
        }

        .title {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .description {
          font-size: 18px;
          height: 100px;

          @include respond(992) {
            height: auto;
          }
        }

        a {
          color: #3faa97;
          border-radius: 20px;
          border: 2px solid #3faa97;
          padding: 10px 30px;
          transition: all ease 0.4s;
          text-decoration: none;

          &:hover {
            color: #fff;
            background-color: #3faa97;
          }
        }
      }
    }
  }
}
