html {
    scroll-behavior: smooth;

    body {
        font-family: "Poppins" !important;
        overflow-x: hidden;

        .container {
            max-width: 1200px;
            padding: 0;

            @include respond(1400) {
                padding: 0px 15px;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .loader {
        display: none;
        justify-content: center;
        align-items: center;
        height: 100px;

        .dot {
            height: 1px;
            width: 4px;
            background-color: #dfc8ae;
        }

        .line {
            height: 1px;
            width: 48px;
            background-color: #dfc8ae;
            margin: 0 4px;
        }

        .wave {
            position: relative;
            width: 55px;
            height: 31px;
            margin: 0 5px;
            overflow: hidden;

            img {
                position: absolute;
                left: -132px;
                top: 0;
                animation: move 5s linear infinite alternate;
            }
        }

        @keyframes move {
            0% {
                left: 0;
            }

            100% {
                left: -132px;
            }
        }
    }

    .woocommerce-info {
        background-color: #ebdccc;
        border-top-color: #282e60;
        color: #282e60;

        &::before {
            color: #282e60;
        }
    }

    .woocommerce-message {
        background-color: #ebdccc;
        border-top-color: #282e60;
        color: #282e60;

        &::before {
            color: #282e60;
        }
    }

    .woocommerce-error {
        background-color: #ebdccc;
        border-top-color: #dc3545;
        color: #dc3545;

        &::before {
            color: #dc3545;
        }
    }

    .button {
        color: #3faa97 !important;
        border-radius: 20px !important;
        border: 2px solid #3faa97 !important;
        padding: 10px 30px !important;
        transition: all ease 0.4s !important;
        text-decoration: none !important;
        background-color: transparent !important;

        &:hover {
            color: #fff !important;
            background-color: #3faa97 !important;
        }

        &:focus {
            outline: none;
        }
    }
}

@include respond(768) {
    #wcc-sticky-list-wrapper.wcc-with-more {
        transform: scale(0.6);
        right: -24px;
        top: 60% !important;
    }
}

body:not(.woocommerce-page) {
    #wcc-sticky-list-wrapper {
        display: none;
    }
}

.mobile-menu {
    text-align: right;
    height: 75px;
    padding-top: 10px;
    background: #000;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;
    width: 100px;
    text-align: center;

    @include respond(768) {
        height: 75px;
        padding-top: 7px;
        width: 80px;
    }

    &.active {
        background: #000;
    }

    .con {
        width: auto;
        margin: 0 auto;
        @include transition(all .7s ease);
        cursor: pointer;
        display: inline-block;

        .bar {
            display: block;
            height: 5px;
            width: 50px;
            background: #fff;
            margin: 10px auto;
            border-radius: 10px;
            @include transition(all .7s ease);
        }

        .middle {
            margin: 0 auto;
        }

        &.clicked {
            .top {
                -webkit-transform: translateY(15px) rotateZ(45deg);
                -moz-transform: translateY(15px) rotateZ(45deg);
                -ms-transform: translateY(15px) rotateZ(45deg);
                -o-transform: translateY(15px) rotateZ(45deg);
                transform: translateY(15px) rotateZ(45deg);
            }

            .bottom {
                -webkit-transform: translateY(-15px) rotateZ(-45deg);
                -moz-transform: translateY(-15px) rotateZ(-45deg);
                -ms-transform: translateY(-15px) rotateZ(-45deg);
                -o-transform: translateY(-15px) rotateZ(-45deg);
                transform: translateY(-15px) rotateZ(-45deg);
            }

            .middle {
                width: 0;
            }
        }
    }
}