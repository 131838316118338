.page-template-community {
    >.top {
        background-image: url("../images/community-bg.png");
        height: 370px;
        display: flex;
        background-size: cover;

        @include respond(768) {
            height: 250px;
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .wave {
                margin-bottom: 20px;
            }

            .title,
            .sub-title {
                font-size: 30px;
                font-weight: 700;
                margin: 0;
                line-height: 1.2;
                color: #fff;
                text-transform: uppercase;
            }

            .sub-title {
                color: #3faa97;
                margin-bottom: 25px;
                font-weight: 300;

                @include respond(768) {
                    text-align: center;
                }
            }
        }
    }

    .community {
        background-color: #f4ede5;
        padding: 40px 0;

        .filter {
            @include respond(1200) {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }

            .filter-btn {
                display: inline-block;
                border: 1px solid #dfc8ae;
                border-radius: 20px;
                padding: 10px 25px;
                margin-right: 8px;
                cursor: pointer;
                color: #282e60;
                font-weight: 300;
                transition: all ease 0.4s;
                text-transform: uppercase;

                @include respond(1200) {
                    margin: 0;
                }

                @include respond(768) {
                    font-size: 14px;
                    padding: 6px 15px;
                }

                &:hover {
                    background-color: #282e60;
                    color: #dfc8ae;
                }

                &.current {
                    background-color: #282e60;
                    color: #dfc8ae;
                }
            }
        }

        .blog-list {
            margin-top: 40px;

            @include respond(992) {
                column-count: 2;
            }

            @include respond(768) {
                column-count: 1;
                display: flex;
                flex-wrap: wrap;
            }

            .card {
                border: unset;
                border-radius: unset;
                box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
                margin-bottom: 2rem;

                @include respond(992) {
                    margin-bottom: 1.25rem;
                }
            }

            .newsletter {
                background-image: url("../images/newsletter-bg.png");
                background-size: cover;
                color: #ffffff;
                padding: 40px 30px 35px 30px;

                @include respond(768) {
                    order: 999;
                }

                h2 {
                    font-size: 34px;
                    font-weight: 700;
                    line-height: 1.2;
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 14px;
                    line-height: 1.2;
                    margin-bottom: 20px;
                }

                .wpcf7 {
                    form {
                        position: relative;

                        input {
                            width: 100%;
                            font-size: 16px;
                            background-color: transparent;
                            border: 1px solid #fff;
                            color: #fff;
                            border-radius: 35px;
                            padding: 7px 20px;
                            transition: all ease 0.4s;

                            &::placeholder {
                                color: #fff;
                            }

                            &:focus {
                                outline: none;
                                padding: 7px 30px;
                            }

                            &[type="submit"] {
                                position: absolute;
                                width: auto;
                                right: 0;
                                top: 0;
                                background-color: #fff;
                                color: #3faa97;
                                font-weight: 700;
                                padding: 7px 30px;

                                &:hover {
                                    background-color: #282e60;
                                    border: 1px solid #282e60;
                                    color: #ffffff;
                                }
                            }

                            &.wpcf7-not-valid {
                                border: 1px solid #dc3545;
                            }
                        }

                        .ajax-loader {
                            display: none;
                        }

                        .wpcf7-not-valid-tip {
                            display: none;
                        }

                        .wpcf7-response-output {
                            position: absolute;
                            margin: 0;
                            border: unset;
                            font-size: 14px;
                            color: #fff;
                        }
                    }
                }
            }

            .blog {
                &:hover {
                    .image {
                        img {
                            height: 110%;
                        }
                    }
                }

                .image {
                    overflow: hidden;
                    width: 100%;
                    height: 220px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        transition: all ease 0.4s;
                    }
                }

                .content {
                    padding: 30px;

                    .cat-list {
                        ul {
                            padding: 0;
                            margin: 0;
                            list-style: none;

                            li {
                                display: inline-block;
                                color: #282e60;
                                font-size: 14px;

                                &:not(:last-child)::after {
                                    content: "|";
                                    margin: 0 5px;
                                }
                            }
                        }
                    }

                    .title {
                        font-size: 18px;
                        font-weight: 700;
                        color: #000;
                        margin: 0;
                        text-transform: uppercase;
                    }

                    .date {
                        color: #3faa97;
                        font-size: 14px;
                    }

                    hr {
                        background-color: #e4d1bc;
                    }

                    p {
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                    .read-more {
                        display: inline-block;
                        margin-top: 10px;
                        color: #3faa97;
                    }
                }
            }
        }

        .loadmore {
            color: #3faa97;
            font-size: 16px;
            text-decoration: underline;
            text-align: center;
            cursor: pointer;

            &.hide {
                display: none;
            }
        }
    }
}