.header {
  height: 82px;

  @include respond(1200) {
    position: relative;
  }

  .header-wrapper {
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: #000;
    padding: 10px;

    @include respond(1200) {
      padding: 0;
      height: 82px;
      display: flex;
    }

    .container {
      display: flex;
      align-items: center;
      position: relative;

      @include respond(1200) {
        justify-content: space-between;
      }

      @include respond(768) {
        .logo {
          img {
            max-width: 170px;
          }
        }
      }

      @include respond(468) {
        .buy {
          display: none;
        }
      }

      .mobile-only {
        display: none;

        &.opened {
          .menu-wrapper {
            display: block;
          }
        }

        @include respond(1200) {
          display: block;
        }
      }

      .menu-wrapper {
        display: flex;
        align-items: center;

        @include respond(1200) {
          position: fixed;
          width: 100%;
          height: 100vh;
          display: block;
          background-color: rgba(#000, 0.7);
          top: 82px;
          left: 0;
          display: none;
          padding: 15px 5px;
          overflow-y: auto;
          padding-bottom: 200px;

          ul {
            position: relative !important;
            left: auto !important;
            top: auto !important;
            flex: 100%;
          }

          .menu {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;

            li {
              background-color: #f4ede5;
              display: flex;
              flex: 100%;
              position: relative;
              flex-wrap: wrap;

              i {
                position: absolute;
                font-size: 26px;
                top: 21px;
                right: 30px;

                @include respond(768) {
                  top: 16px;
                }

                @include respond(468) {
                  top: 13px;
                }
              }

              &:not(:last-child) {
                margin-bottom: 5px;
              }

              &.active {
                background-color: rgba(0, 0, 0, 0.8);

                a {
                  color: #3faa97;
                }
              }

              a {
                padding: 10px 30px !important;
                display: block;
                font-size: 30px;
                color: #000;

                @include respond(768) {
                  font-size: 24px;
                }

                @include respond(468) {
                  font-size: 20px;
                }
              }
            }
          }
        }

        > ul {
          list-style: none;
          margin: 0;
          padding-inline-start: 10px;

          > li {
            position: relative;
            display: inline-block;
            margin: 0 7px;

            @include respond(1200) {
              .sub-menu {
                display: none;
              }
            }

            &.current-menu-item {
              a {
                color: #3faa97;
              }
            }

            &.current-menu-parent {
              a {
                color: #3faa97;
              }
            }

            > a {
              color: #fff;
              font-size: 18px;
              text-decoration: none;
              transition: all ease 0.4s;

              &:hover {
                padding-bottom: 30px;
                color: #3faa97;
              }
            }

            &.product-menu {
              > ul {
                &.sub-menu {
                  display: none;
                  position: absolute;
                  top: 50px;
                  margin: 0;
                  padding: 0;
                  list-style: none;
                  background-color: #f4ede5;
                  border-top: 4px solid #3faa97;
                  padding: 10px 20px;

                  @include respond(1200) {
                    border-top: 0;
                  }

                  &::after {
                    content: '';
                    position: absolute;
                    top: -9px;
                    transform: rotate(45deg);
                    background-color: #f4ede5;
                    border-left: 4px solid #3faa97;
                    border-top: 4px solid #3faa97;
                    height: 12px;
                    width: 12px;

                    @include respond(1200) {
                      display: none;
                    }
                  }

                  li {
                    padding: 9px 0;
                    padding-right: 30px;

                    &:not(:last-child) {
                      border-bottom: 1px solid #dfc8ae;
                    }

                    &.current-menu-item {
                      a {
                        color: #3faa97;
                      }
                    }

                    a {
                      font-size: 17px;
                      color: #000000;
                      text-decoration: none;
                      transition: all ease 0.4s;

                      @include respond(1200) {
                        font-size: 21px;
                      }

                      &:hover {
                        color: #3faa97;
                      }
                    }
                  }
                }
              }
            }

            &.play {
              > ul {
                &.sub-menu {
                  display: none;
                  position: absolute;
                  top: 50px;
                  margin: 0;
                  padding: 40px;
                  list-style: none;
                  background-color: #fff;
                  border-top: 4px solid #fff;
                  width: 290px;
                  // height: 375px;
                  column-count: 1 !important;

                  @include respond(1200) {
                    background-color: #f4ede5;
                    border-top: 0;
                    padding: 10px 20px;
                    width: 100%;
                    height: auto;
                    column-count: unset !important;
                  }

                  &::after {
                    content: '';
                    position: absolute;
                    top: -9px;
                    left: 15px;
                    transform: rotate(45deg);
                    background-color: #fff;
                    border-left: 4px solid #fff;
                    border-top: 4px solid #fff;
                    height: 12px;
                    width: 12px;

                    @include respond(1200) {
                      display: none;
                    }
                  }

                  > li {
                    margin-bottom: 20px;

                    &.menu-item-has-children {
                      > a {
                        color: #3faa97;
                        font-weight: 600;
                      }

                      > ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;

                        > li {
                          padding: 0;
                        }
                      }
                    }

                    a {
                      font-size: 17px;
                      color: #000000;
                      text-decoration: none;
                      transition: all ease 0.4s;

                      @include respond(1200) {
                        font-size: 21px;
                      }

                      &:hover {
                        color: #3faa97;
                      }
                    }
                  }

                  > .downloads-menu {
                    position: absolute;
                    top: -4px;
                    right: -260px;
                    height: calc(100% + 4px);
                    width: 260px;
                    background-color: #efe4d7;
                    padding: 45px 45px;

                    @include respond(1200) {
                      position: relative;
                      top: auto;
                      right: auto;
                      height: auto;
                      width: 100%;
                      background-color: transparent;
                      padding: 0px 0;
                    }

                    > a {
                      color: #3faa97;
                      font-weight: 600;
                    }

                    > ul {
                      padding: 0;
                      margin: 0;
                      list-style: none;

                      > li {
                        padding: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        > form {
          margin-left: 10px;
          display: flex;
          align-items: center;

          @include respond(1400) {
            position: relative;
          }

          @include respond(1200) {
            position: relative;
            margin: 0;
            width: 100%;
            padding: 10px;
          }

          > button {
            background-color: transparent;
            border: none;
            padding: 0;
            margin-right: 10px;

            &:focus {
              outline: none;
            }
          }

          > input {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #fff;
            font-size: 18px;
            color: #fff;
            width: 100px;
            display: none;

            @include respond(1400) {
              position: absolute;
              bottom: -77px;
              left: -70px;
              background: rgb(0, 0, 0);
              width: fit-content;
              border-top: 1px solid #fff;
              padding: 10px;
            }

            @include respond(1200) {
              position: relative;
              left: auto;
              top: auto;
              bottom: auto;
              width: auto;
              flex: 0 auto;
              flex-grow: 1;
              display: flex !important;
            }

            &::placeholder {
              color: rgb(161, 161, 161);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .cart {
        margin-left: auto;
        padding-right: 10px;

        @include respond(1200) {
          margin-left: initial;
        }

        @include respond(768) {
          padding-bottom: 11px;
        }

        img {
          width: 35px;
        }
      }

      .buy {
        a {
          color: #fff;
          text-decoration: none;
          transition: all ease 0.4s;
          border: 2px solid #3faa97;
          color: #3faa97;
          padding: 10px 10px;
          border-radius: 20px;

          @include respond(768) {
            padding: 6px 10px;
          }

          &:hover {
            background-color: #3faa97;
            color: #fff;
          }
        }
      }

      .login {
        position: absolute;
        right: -150px;

        a {
          color: #fff;
          text-decoration: none;
          transition: all ease 0.4s;

          &:hover {
            color: #3faa97;
          }
        }
      }
    }
  }
}
