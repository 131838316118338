.search {
    >.top {
        background-image: url("../images/search-bg.png");
        text-align: center;
        padding: 30px;

        img {
            margin-bottom: 20px;
        }

        h2 {
            line-height: 1.2;
            color: #fff;
            font-weight: 600;
        }
    }

    .search-wrapper {
        padding: 25px 0px;
        background-color: #f4ede5;
        min-height: 100vh;

        .for {
            span {
                color: #282e60;

                &strong {
                    font-weight: 700;
                }
            }
        }

        .results {
            margin: 30px 0px;

            .result {
                &:not(:last-child) {
                    margin-bottom: 50px;
                    padding-bottom: 50px;
                    border-bottom: 1px solid #e4d1bc;
                }

                .title {
                    text-align: left;
                    font-size: 22px;
                    color: #282e60;
                    font-weight: 700;
                }

                p {}

                a {
                    border: 1px solid #282e60;
                    color: #282e60;
                    border-radius: 20px;
                    padding: 5px 10px;
                    text-decoration: none;
                    transition: all ease 0.4s;

                    &:hover {
                        background-color: #282e60;
                        color: #f4ede5;
                    }
                }
            }

            .no-result {
                color: #282e60;
                text-align: center;
                padding-top: 200px;
                font-size: 30px;
            }
        }
    }
}