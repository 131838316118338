.footer {
    background-image: url("../images/footer-bg.png");
    padding: 60px 0;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-repeat: no-repeat;

    @include respond(992) {
        >.container {
            >.row {
                >div {
                    @include respond(768) {
                        flex: 0 auto;
                        max-width: fit-content;

                        h2 {
                            font-size: 35px !important;
                        }
                    }

                    &.text {
                        flex: 100%;
                        max-width: 100%;
                    }

                    &.col-1 {
                        display: none;
                    }

                    &.social {
                        flex: 0 auto;
                        max-width: fit-content;
                    }
                }
            }
        }
    }

    &.colored {
        background-color: #1f1134;
        background-image: none;
    }

    .text {
        .line {
            height: 1px;
            width: 35px;
            background-color: #dfc8ae;
            margin-bottom: 20px;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                color: transparent;
                background-color: #dfc8ae;
                height: 1px;
                width: 3px;
                right: -6px;
            }
        }

        h2 {
            font-weight: 200;
            font-size: 24px;
            margin-bottom: 20px;
        }

        p {
            font-weight: 200;
            font-size: 16px;
            text-align: justify;

            @include respond(768) {
                font-size: 16px !important;
            }

            span {
                color: #328f7f;
            }
        }
    }

    .menu {
        .line {
            height: 1px;
            width: 35px;
            background-color: #dfc8ae;
            margin-bottom: 20px;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                color: transparent;
                background-color: #dfc8ae;
                height: 1px;
                width: 3px;
                right: -6px;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                margin-bottom: 15px;

                a {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 200;
                }
            }
        }
    }

    .social {
        .line {
            height: 1px;
            width: 35px;
            background-color: #dfc8ae;
            margin-bottom: 20px;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                color: transparent;
                background-color: #dfc8ae;
                height: 1px;
                width: 3px;
                right: -6px;
            }
        }

        h2 {
            font-size: 16px;
            font-weight: 200;
            margin-bottom: 15px;
        }

        a {
            margin-left: 5px;
        }
    }
}