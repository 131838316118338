.page-template-career {
    .top {
        background-image: url("../images/career-bg.png");
        height: 368px;

        .container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .title {
                font-size: 30px;
                font-weight: 700;
                margin: 0;
                line-height: 1.2;
                color: #fff;
                text-transform: uppercase;
            }
        }
    }

    .content {
        background-color: #f4ede5;
        text-align: center;
        padding: 60px 0;

        .wave {
            margin-bottom: 20px;
        }

        .title,
        .sub-title {
            font-size: 30px;
            font-weight: 300;
            margin: 0;
            line-height: 1.2;
        }

        .sub-title {
            color: #3faa97;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            margin-bottom: 35px;
            padding: 0 150px;

            @include respond(768) {
                padding: 0;
            }
        }
    }

    .jobs {
        background-color: #ebdccc;
        padding: 50px 0;

        .job {
            position: relative;
            width: 950px;
            margin: 20px auto;
            padding: 50px;
            background-color: #efe4d7;
            display: flex;

            @include respond(992) {
                width: 100%;
            }

            @include respond(768) {
                flex-wrap: wrap;
            }

            .title {
                width: 30%;

                @include respond(768) {
                    width: 100%;
                    margin-bottom: 50px;
                }

                .job-title {
                    font-size: 36px;
                    font-weight: 600;
                    margin: 0;
                    line-height: 1.2;
                }

                .job-type {
                    font-size: 16px;
                    font-weight: 600;
                    color: #3faa97;
                    margin-bottom: 50px;
                }

                .aplly-btn {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 30px;
                    padding: 10px 35px;
                    color: #fff;
                    text-decoration: none;
                    transition: all ease 0.4s;
                    background-color: #3faa97;

                    @include respond(768) {
                        display: block;
                        text-align: center;
                        font-size: 20px;
                    }

                    &:hover {
                        background-color: #282e60;
                    }
                }
            }

            .description {
                width: 70%;

                @include respond(768) {
                    width: 100%;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 30px;
                }

                .requirements {
                    display: none;

                    .requirement {
                        img {
                            margin-right: 15px;
                        }

                        .label {
                            display: inline-block;
                            font-size: 16px;
                            font-weight: 600;
                            width: 150px;
                        }

                        .text {
                            font-size: 16px;
                        }
                    }

                    .more-info {
                        text-align: center;
                        padding: 30px;

                        p {
                            margin: 0;
                            font-size: 16px;
                            line-height: 1.6;
                            margin-bottom: 10px;
                        }

                        a {
                            color: #3faa97;
                            font-size: 16px;
                        }
                    }

                    .apply-from {
                        margin-top: 20px;

                        form {
                            display: flex;

                            #job-name {
                                display: none !important;
                            }

                            input {
                                width: 100%;

                                &[type="file"] {
                                    display: none;
                                }
                            }

                            label#for {
                                width: 80%;
                                border: 1px solid #3faa97;
                                padding: 10px 20px;
                                color: #3faa97;
                                border-top-left-radius: 30px;
                                border-bottom-left-radius: 30px;
                                color: #3faa97;
                                margin: 0;
                                overflow: hidden;
                            }

                            input[type="submit"] {
                                width: 20%;
                                border: 1px solid #3faa97;
                                padding: 10px 20px;
                                color: #3faa97;
                                border-top-right-radius: 30px;
                                border-bottom-right-radius: 30px;
                                color: #fff;
                                background-color: #3faa97;
                                transition: all ease 0.4s;

                                @include respond(768) {
                                    width: 40%;
                                }

                                &:hover {
                                    background-color: #282e60;
                                    border: 1px solid #282e60;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }
                        }

                        .wpcf7-response-output {
                            position: absolute;
                            bottom: 0;
                            border: unset;
                            font-size: 14px;
                        }

                        .wpcf7-not-valid-tip {
                            display: none;
                        }
                    }
                }
            }

            .toggle-btn {
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: #f3eae1;
                font-size: 30px;
                color: #3faa97;
                font-weight: 200;
                line-height: 1;
                height: 55px;
                width: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .email {
        background-color: #ebdccc;
        text-align: center;
        padding-bottom: 50px;

        p {
            margin: 0;
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 10px;
        }

        a {
            color: #3faa97;
            font-size: 16px;
        }
    }
}