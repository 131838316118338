.tax-product_cat {
    >.top {
        background-image: url("../images/accessories-bg.png");
        padding-top: 140px;
        padding-bottom: 120px;
        text-align: center;

        .title {
            font-size: 30px;
            font-weight: 700;
            margin: 0;
            line-height: 1.2;
            color: #fff;
            margin-bottom: 40px;
        }

        .cat-list {
            margin: 0;
            padding: 0;
            list-style: none;

            @include respond(768) {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }

            li {
                display: inline-block;

                @include respond(768) {
                    display: flex;
                }

                a {
                    border: 1px solid #fff;
                    color: #fff;
                    padding: 10px 30px;
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 600;
                    text-decoration: none;
                    transition: all ease 0.4s;
                    margin: 0 6px;

                    @include respond(768) {
                        margin: 0;
                    }

                    &:hover {
                        background-color: #3faa97;
                        border: 1px solid #3faa97;
                    }

                    &.active {
                        background-color: #3faa97;
                        border: 1px solid #3faa97;
                    }
                }
            }
        }
    }

    .description {
        background-color: #f4ede5;
        text-align: center;
        padding: 60px;

        @include respond(768) {
            padding: 0;
        }

        .wave {
            margin-bottom: 20px;
        }

        .title,
        .sub-title {
            font-size: 30px;
            font-weight: 300;
            margin: 0;
            line-height: 1.2;
            text-transform: uppercase;
        }

        .sub-title {
            color: #3faa97;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            margin-bottom: 35px;
        }

        iframe {
            width: 800px;
            height: 400px;

            @include respond(992) {
                width: 100%;
            }
        }

        .files {
            margin: auto;
            margin-top: 20px;
            display: flex;
            justify-content: space-evenly;
            width: 700px;

            @include respond(992) {
                width: 100%;
            }

            .download {
                text-decoration: none;

                &:hover {
                    .icon {
                        img {
                            transform: scale(1);
                        }
                    }
                }

                .icon {
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        transform: scale(0.9);
                        transition: all ease 0.4s;
                    }
                }

                h2 {
                    font-size: 16px;
                    color: #000;
                }
            }
        }
    }

    .products {
        background-color: #f4ede5;

        @include respond(768) {
            padding-bottom: 40px;
        }

        .sep {
            width: 100%;

            @include respond(768) {
                object-fit: none;
                height: fit-content;
                margin: 20px 0;
            }
        }

        .product {
            height: auto;
            padding: 75px 0px;

            @include respond(992) {
                padding: 75px 15px;
            }

            @include respond(768) {
                padding: 0 15px;

                >div {
                    max-width: 100%;
                    flex: 100%;
                }
            }

            .image {
                padding: 0;
                text-align: center;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;

                    @include respond(1200) {
                        height: auto;
                    }
                }
            }

            .content {
                padding: 0;

                h2 {
                    font-size: 18px;
                    font-weight: 700;
                }

                p {
                    font-size: 16px;
                    font-weight: 300;
                }

                .price {
                    font-size: 36px;
                    color: #3faa97;
                    font-weight: 600;
                }

                .technical-fields {
                    margin-bottom: 20px;

                    .field {
                        color: #000;
                        font-weight: 300;
                        align-content: center;
                        font-size: 14px;

                        img {
                            margin-right: 10px;
                        }
                    }
                }

                a {
                    background-color: transparent;
                    font-weight: 700;
                    border-radius: 30px !important;
                    padding: 10px 20px !important;
                    transition: all ease 0.4s;
                    text-decoration: none;
                    line-height: unset;

                    &.add_to_cart_button {
                        border: 1px solid #3faa97;
                        color: #3faa97;
                        padding: 10px 40px !important;
                        transition: all ease 0.4s;

                        &.added {
                            background-color: #3faa97;
                            color: #fff;
                        }

                        &:hover {
                            background-color: #3faa97;
                            color: #fff;
                        }

                        &::after {
                            position: absolute;
                            right: 20px;
                            margin: 0;
                            top: unset;
                            vertical-align: unset;
                        }
                    }

                    &.added_to_cart {
                        border: 1px solid #282e60;
                        color: #282e60;

                        @include respond(768) {
                            margin-left: 10px;
                        }

                        &:hover {
                            background-color: #282e60;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}