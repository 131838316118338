.single-post {
    position: relative;

    .medias {
        position: fixed;
        right: 0;
        top: 100px;
        width: 70px;
        cursor: pointer;

        @include respond(1200) {
            position: fixed;
            right: 0;
            top: 100px;
            width: 70px;
            cursor: pointer;
        }

        .media {
            position: absolute;
            width: 210px;
            right: -140px;
            transition: all ease 0.4s;
            text-decoration: none;

            @include respond(1200) {
                width: 190px;
            }

            // @include respond(768) {
            display: none;
            // }

            &:hover {
                right: 0px;
            }

            .icon {
                height: 70px;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3faa97;

                @include respond(1200) {
                    height: 50px;
                    width: 50px;

                    img {
                        max-width: 30%;
                    }
                }
            }

            .text {
                height: 70px;
                width: 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #7eb4aa;
                font-style: 22px;
                color: #fff;
                font-weight: 600;

                @include respond(1200) {
                    height: 50px;
                }
            }

            &-2 {
                .icon {
                    background-color: #ddc9b3;
                }

                .text {
                    background-color: #ddd1c4;
                }
            }

            &-3 {
                .icon {
                    background-color: #282e60;
                }

                .text {
                    background-color: #373b66;
                }
            }

            &-4 {
                .icon {
                    background-color: #63d0bd;
                }

                .text {
                    background-color: #7ad1c3;
                }
            }

            &-5 {
                .icon {
                    background-color: #2c1641;
                }

                .text {
                    background-color: #423450;
                }
            }
        }
    }

    >.top {
        padding-top: 350px;
        padding-bottom: 70px;
        background-image: url("../images/single-bg.png");
        background-size: cover;
        background-repeat: no-repeat;

        @include respond(1600) {
            padding-top: 150px;
        }

        @include respond(768) {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        >.container {
            max-width: 850px;
        }

        a {
            display: inline-block;
            margin-bottom: 25px;

            img {
                transform: rotate(180deg);
                height: 11px;
                margin-right: 5px;
            }

            color: #3faa97;
        }

        .title {
            color: #fff;
            font-size: 60px;
            font-weight: 700;
            margin-bottom: 25px;

            @include respond(768) {
                font-size: 40px;
            }
        }

        .date {
            font-size: 36px;
            color: #3faa97;
            margin: unset;
        }
    }

    .content {
        background-color: #f4ede5;
        padding: 60px 0;

        >.container {
            max-width: 850px;

            @include respond(1200) {
                max-width: 100%;
            }

            .cat-list {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        display: inline-block;
                        color: #282e60;

                        &:not(:last-child) {
                            &::after {
                                content: "|";
                                color: #282e60;
                                margin: 0 5px;
                            }
                        }
                    }
                }
            }

            hr {
                margin: 20px 0;
            }

            .text {
                p {
                    font-size: 16px;
                    font-weight: 500;

                    &.highlight {
                        color: #3faa97;
                        font-size: 20px;
                        font-weight: 600;
                        font-style: italic;
                    }
                }
            }
        }
    }

    .comments {
        background-color: #f4ede5;
        padding: 15px 0 0 0;

        .container {
            max-width: 850px;

            @include respond(1200) {
                max-width: 100%;
            }
        }

        .title {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 0;
            margin-bottom: 25px;
        }

        .comments-wrapper {
            .comment {
                display: flex;
                border-bottom: 1px solid #dbd5ce;
                margin-bottom: 30px;
                padding-bottom: 30px;

                .image {
                    width: 10%;

                    img {
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                        border-radius: 100%;
                    }
                }

                .content {
                    padding: 0;
                    width: 80%;
                    padding: 0 20px;

                    .name {
                        line-height: 1;
                        margin-bottom: 10px;
                        font-size: 18px;
                        font-weight: 700;
                    }

                    .date {
                        line-height: 1;
                        margin-bottom: 10px;
                        font-size: 14px;
                        color: #95918c;
                        font-weight: 600;
                    }

                    .text {
                        font-size: 16px;
                    }
                }

                .btns {
                    width: 10%;

                    a {
                        display: block;
                        background-color: #928e89;
                        color: #fff;
                        border-radius: 20px;
                        padding: 7px 5px;
                        width: 75px;
                        text-align: center;
                        text-decoration: none;
                        line-height: 1;
                        transition: all ease 0.4s;
                        margin-bottom: 5px;
                        font-size: 14px;
                        font-weight: 300;

                        &:hover {
                            background-color: #5f5f5f;
                        }
                    }
                }
            }

            .children {
                padding: 0;

                .comment {
                    .image {
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "↳";
                            font-size: 22px;
                            left: -20px;
                            top: 15px;
                            color: #ccc6bf;
                        }
                    }
                }
            }
        }

        .replay-wrapper {
            padding-bottom: 20px;

            form {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                textarea,
                input {
                    background-color: #e5ded7;
                    border: unset;
                    resize: none;
                    width: 100%;
                    margin-bottom: 20px;
                    border-radius: 10px;
                    padding: 15px;
                    transition: all ease 0.4s;

                    &:focus {
                        outline: none;
                        background-color: #674d79;
                        color: #fff;

                        &::placeholder {
                            color: #fff;
                        }
                    }

                    &::placeholder {
                        color: #a29d98;
                    }
                }

                textarea {
                    height: 180px;
                }

                input {
                    width: 31%;

                    @include respond(768) {
                        width: 100%;
                    }

                    &:nth-of-type(2) {
                        margin-left: 20px;
                        margin-right: 20px;

                        @include respond(768) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    &[type="submit"] {
                        background-color: #3faa97;
                        color: #fff;
                        width: unset;
                        font-weight: 600;
                        border-radius: 30px;
                        padding: 15px 50px;
                        margin: 0;
                    }
                }
            }
        }
    }
}