.page-template-homepage {
  .video-popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000, $alpha: 0.9);
    top: 0;
    z-index: 999;
    display: none;
    justify-content: center;
    align-items: center;

    .container {
      position: relative;
      background-color: #282e60;
      border-radius: 10px;
      height: 80%;
      max-width: 69%;
      padding: 10px;

      iframe {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      .close-btn {
        top: -15px;
        right: -15px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-size: 18px;
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #3faa97;
        cursor: pointer;

        &:hover {
          background-color: #369483;
        }
      }
    }
  }

  .top {
    position: relative;
    height: calc(100vh - 82px);
    overflow: hidden;

    .video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      background-color: rgba($color: #000000, $alpha: 0.65);
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 190px;

      @include respond(1400) {
        padding-top: 120px;
      }

      @include respond(1200) {
        text-align: center;
      }

      @include respond(768) {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      img {
        margin-bottom: 50px;

        @include respond(768) {
          margin-bottom: 30px;
        }
      }

      h1 {
        font-size: 70px;
        font-weight: 800;
        margin-bottom: 30px;

        @include respond(768) {
          font-size: 40px;
        }

        @include respond(468) {
          font-size: 30px;
        }
      }

      p {
        font-size: 22px;
        text-align: center;

        @include respond(768) {
          font-size: 18px;
        }
      }

      .btn {
        display: inline-block;
        color: #fff;
        border-radius: 20px;
        border: 2px solid #3faa97;
        padding: 10px 30px;
        transition: all ease 0.4s;
        text-decoration: none;
        margin-top: 100px;
        background-color: #3faa97;

        @include respond(768) {
          margin-top: 40px;
        }

        &:hover {
          background-color: #282e60;
          border: 2px solid #282e60;
        }
      }
    }
  }

  .content {
    background-color: #f4ede5;
    padding: 130px 0;

    @include respond(768) {
      padding: 40px 0;
    }

    @include respond(468) {
      padding: 0px 0 40px;
    }

    .text {
      text-align: center;

      .wave {
        margin-bottom: 20px;
      }

      .title,
      .sub-title {
        font-size: 30px;
        font-weight: 300;
        margin: 0;
        line-height: 1.2;
        text-transform: uppercase;
      }

      .sub-title {
        color: #3faa97;
        margin-bottom: 25px;
        text-transform: uppercase;
      }

      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.6;
        margin-bottom: 35px;
      }

      a {
        color: #3faa97;
        border-radius: 20px;
        border: 2px solid #3faa97;
        padding: 10px 30px;
        transition: all ease 0.4s;
        text-decoration: none;

        &:hover {
          color: #fff;
          background-color: #3faa97;
        }
      }

      img {
        margin-top: 30px;
      }
    }

    .text-row {
      @include respond(768) {
        &:first-child {
          flex-wrap: wrap-reverse;
        }

        > div {
          flex: 100%;
          max-width: 100%;
        }
      }

      &:first-child {
        margin-bottom: 130px;

        @include respond(768) {
          margin-bottom: 30px;
        }
      }

      .image {
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      .text {
        text-align: left;
        padding: 25px 15px 25px 60px;

        @include respond(1400) {
          padding: 25px 15px;
        }

        @include respond(768) {
          text-align: center;
        }

        .wave {
          margin-bottom: 20px;
        }

        .title,
        .sub-title {
          font-size: 30px;
          font-weight: 300;
          margin: 0;
          line-height: 1.2;
        }

        .sub-title {
          color: #3faa97;
          margin-bottom: 25px;
        }

        p {
          font-size: 18px;
          font-weight: 300;
          line-height: 1.6;
          margin-bottom: 20px;
        }

        a {
          display: inline-block;
          color: #3faa97;
          border-radius: 20px;
          border: 2px solid #3faa97;
          padding: 10px 30px;
          transition: all ease 0.4s;
          text-decoration: none;

          &:hover {
            color: #fff;
            background-color: #3faa97;
          }
        }
      }

      .slider-wrapper {
        position: relative;

        .arrows-bg {
          position: absolute;
          left: 15px;
          bottom: 0;
          width: 150px;
          height: 50px;
          background-color: rgba(223, 200, 174, 0.85);
          border-radius: 20px;
          z-index: 1;
        }

        .slider {
          height: 100%;

          .slick-track,
          .draggable {
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
          }

          .slick-arrow {
            height: 25px;
            width: 16px;
            top: unset;
            bottom: 0;
            left: 40px;
            z-index: 1;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 25px;
              width: 16px;
              background-image: url('../images/slick-arrow-black.png');
              opacity: 1;
            }

            &:hover {
              &::before {
                background-image: url('../images/slick-arrow-green.png');
              }
            }

            &.slick-prev {
              &::before {
                transform: rotate(180deg);
              }
            }

            &.slick-next {
              left: 95px;
            }
          }
        }
      }
    }
  }

  .get-to-know {
    background-image: url('../images/get-to-know-bg.png');
    text-align: center;
    background-size: cover;
    color: #fff;
    padding: 90px 0;

    @include respond(768) {
      background-size: auto;
    }

    .wave {
      margin-bottom: 15px;
    }

    .title {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 20px;
    }

    .slider {
      margin-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
      padding: 0 90px;

      @include respond(992) {
        padding: 0;
      }

      .slide {
        width: 33%;
        padding: 10px;

        @include respond(768) {
          width: 100%;
          padding: 0;
          margin-bottom: 10px;
        }

        .slide-content {
          border-radius: 20px;
          text-align: left;
          background-color: #000;
          padding: 20px;
          transition: opacity ease 0.5s;
          overflow: hidden;
          height: 100%;
          iframe {
            width: 100%;
            margin-bottom: 10px;
            height: 250px;
          }

          .num {
            color: #dfc8ae;
            font-size: 18px;
            display: inline-block;
          }

          h2 {
            font-size: 18px;
            // margin-bottom: 15px;
            display: inline-block;
          }

          p {
            font-size: 18px;
            line-height: 1.6;
            display: none;
          }
        }
      }
    }

    a {
      color: #3faa97;
      border-radius: 20px;
      border: 2px solid #3faa97;
      padding: 10px 30px;
      transition: all ease 0.4s;
      text-decoration: none;

      &:hover {
        color: #fff;
        background-color: #3faa97;
      }
    }
  }

  .accessories {
    background-color: #efe4d8;
    padding: 130px 0;
    text-align: center;

    @include respond(768) {
      padding: 70px 0;
    }

    .wave {
      margin-bottom: 20px;
    }

    .title,
    .sub-title {
      font-size: 30px;
      font-weight: 300;
      margin: 0;
      line-height: 1.2;
    }

    .sub-title {
      color: #3faa97;
      margin-bottom: 25px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;
      margin-bottom: 35px;
    }

    .accessories-list {
      background-image: url('../images/beige-wave-big-accessories.png');
      background-position: top;
      background-repeat: no-repeat;
      padding-top: 70px;

      @include respond(768) {
        padding-top: 40px;
        background-attachment: fixed;
        background-position: center;
      }

      .accessory {
        &:hover {
          .image {
            img {
              transform: scale(1.1);
            }
          }
        }

        .image {
          height: 240px;
          width: 160px;
          padding: 20px 0;
          margin: auto;
          overflow: hidden;

          @include respond(768) {
            height: auto;
            width: 100%;
          }

          img {
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all ease 0.4s;
          }
        }

        .title {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .description {
          font-size: 18px;
          height: 100px;

          @include respond(992) {
            height: auto;
          }
        }

        a {
          color: #3faa97;
          border-radius: 20px;
          border: 2px solid #3faa97;
          padding: 10px 30px;
          transition: all ease 0.4s;
          text-decoration: none;

          &:hover {
            color: #fff;
            background-color: #3faa97;
          }
        }
      }
    }
  }

  .audience-experience {
    background-image: url('../images/audience-experience-bg.png');
    padding: 80px 0;
    text-align: center;

    @include respond(768) {
      padding: 70px 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .wave {
      margin-bottom: 20px;
    }

    .title,
    .sub-title {
      font-size: 30px;
      font-weight: 300;
      margin: 0;
      line-height: 1.2;
      color: #fff;
    }

    .sub-title {
      color: #3faa97;
      margin-bottom: 25px;
    }

    .slick-dots {
      display: inline-block;
      width: unset;
      bottom: 40px;
      left: 35%;

      li {
        button {
          &::before {
            font-size: 50px;
            color: #fff;
          }
        }
      }
    }

    .experience {
      margin: 0;
      height: 350px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 20px;
      padding: 40px 20px;

      @include respond(992) {
        height: auto;
      }

      .image,
      .content {
        background: none;
        height: 100%;
        color: #fff;
        text-align: left;

        @include respond(768) {
          flex: 100%;
          max-width: 100%;
        }
      }

      .image {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 20px;

          @include respond(992) {
            min-height: 260px;
          }
        }
      }

      .content {
        padding: 15px 15px;
        padding-top: 40px;

        .text {
          text-align: left !important;

          p {
            font-size: 18px;
            font-weight: 600;
            position: relative;

            &::before {
              position: absolute;
              content: '"';
              display: block;
              color: #3faa97;
              font-size: 60px;
              top: -40px;
              left: -5px;
            }

            &::after {
              content: '"';
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .by {
          h2 {
            font-size: 16px;
            font-weight: 200;
            margin: 0;
          }

          p {
            font-size: 16px;
            font-weight: 200;
          }
        }
      }
    }
  }
}
