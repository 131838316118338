.page-template-default {
    >.top {
        background-image: url("../images/search-bg.png");
        text-align: center;
        padding: 30px;

        img {
            margin-bottom: 20px;
        }

        h2 {
            line-height: 1.2;
            color: #fff;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .content {
        padding: 50px 0px;
        background-color: #f4ede5;
    }
}